import React, { useState } from "react";
import { Trans } from "react-i18next";
import "./Cookie.scss";

const Cookie = () => {
    const [isCookie, setIsCookie] = useState<string | null>(localStorage.getItem('isCookie'));

    const onAccept = () => {
        localStorage.setItem('isCookie', 'true');
        setIsCookie('true');
    };
    const onReject = () => window.history.back();

    if (isCookie) {
        return null;
    }

    return (
        <div className="cookie">
            <div className="content">
                <p>
                    <Trans i18nKey="cookie.text">
                        We are using cookies to ensure you get the best experience on our website. Cookies keep our site
                        secure and reliable. They let us personalize to you and help us analyse how the site is used.
                    </Trans>
                </p>
                <div className="buttons">
                    <button className="white" onClick={onAccept}>
                        <Trans i18nKey="cookie.accept">Accept All</Trans>
                    </button>
                    <button className="transparent" onClick={onReject}>
                        <Trans i18nKey="cookie.reject">Reject non-essential</Trans>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Cookie;
