import React from "react";
import { Trans } from "react-i18next";
import { TIME_MORNING, TIME_AFTERNOON, TIME_EVENING, TIME_NIGHT } from "../../constants";

interface ITimeSelector {
    currentTime: number,
    onTimeChange: (time: number) => void
}
const TimeSelector = ({ currentTime, onTimeChange }: ITimeSelector) => {
    return (
        <div className="submenu submenu-time">
            <div
                className={`${currentTime === TIME_MORNING ? 'active' : null}`}
                onClick={() => onTimeChange(TIME_MORNING)}
            >
                <Trans i18nKey="tour.time.morning">Morning</Trans>
            </div>
            <div
                className={`${currentTime === TIME_AFTERNOON ? 'active' : null}`}
                onClick={() => onTimeChange(TIME_AFTERNOON)}
            >
                <Trans i18nKey="tour.time.day">Day</Trans>
            </div>
            <div
                className={`${currentTime === TIME_EVENING ? 'active' : null}`}
                onClick={() => onTimeChange(TIME_EVENING)}
            >
                <Trans i18nKey="tour.time.evening">Evening</Trans>
            </div>
            <div
                className={`${currentTime === TIME_NIGHT ? 'active' : null}`}
                onClick={() => onTimeChange(TIME_NIGHT)}
            >
                <Trans i18nKey="tour.time.night">Night</Trans>
            </div>
        </div>
    );
};

export default TimeSelector;