import React, { useRef, useEffect } from "react";
import { AnimationItem } from "lottie-web";
import { Player } from '@lottiefiles/react-lottie-player';
import { LEVEL_10M, LEVEL_100M, LEVEL_5M, LEVEL_2F, LEVEL_2M, LEVEL_1F } from "../../constants";
import "./Control.scss";

interface IControl {
    isReady: boolean;
    isHidden: boolean;
    currentDirection: number;
    onDirectionChange: (direction: number) => void,
    currentLevel: number,
    onLevelChange: (level: number) => void,
}
const Control = ({ isReady, isHidden, currentDirection, onDirectionChange, currentLevel, onLevelChange }: IControl) => {
    const controlRef = useRef<AnimationItem | null>(null);

    const getDirection = (steps: number) => {
        return (currentDirection + steps + 4) % 4;
    };

    useEffect(() => {
        if (!isReady) {
            return;
        }

        controlRef.current?.setSegment(0, 26);
        controlRef.current?.play();
    }, [isReady]);

    return (
        <div className="control" style={{display: isHidden ? 'none' : 'block'}}>
            <div className="pulse" />
            <div
                className={`up ${currentLevel === LEVEL_100M || currentLevel === LEVEL_5M || currentLevel === LEVEL_2F ? 'disabled' : ''}`}
                onClick={() => {
                    if (currentLevel === LEVEL_100M || currentLevel === LEVEL_5M || currentLevel === LEVEL_2F) {
                        return;
                    }

                    controlRef.current?.stop();
                    controlRef.current?.setSegment(77, 111);
                    controlRef.current?.stop();
                    controlRef.current?.play();

                    onLevelChange(currentLevel === LEVEL_1F ? currentLevel + 1 : currentLevel - 1);
                }}
            />
            <div
                className={`left ${currentLevel === LEVEL_100M ? 'disabled' : ''}`}
                onClick={() => {
                    if (currentLevel === LEVEL_100M) {
                        return;
                    }

                    controlRef.current?.stop();
                    controlRef.current?.setSegment(156, 202);
                    controlRef.current?.stop();
                    controlRef.current?.play();

                    onDirectionChange(getDirection(1));
                }}
            />
            <div
                className={`right ${currentLevel === LEVEL_100M ? 'disabled' : ''}`}
                onClick={() => {
                    if (currentLevel === LEVEL_100M) {
                        return;
                    }

                    controlRef.current?.stop();
                    controlRef.current?.setSegment(30, 67);
                    controlRef.current?.stop();
                    controlRef.current?.play();

                    onDirectionChange(getDirection(-1));
                }}
            />
            <div
                className={`down ${currentLevel === LEVEL_10M || currentLevel === LEVEL_2M || currentLevel === LEVEL_1F ? 'disabled' : ''}`}
                onClick={() => {
                    if (currentLevel === LEVEL_10M || currentLevel === LEVEL_2M || currentLevel === LEVEL_1F) {
                        return;
                    }

                    controlRef.current?.stop();
                    controlRef.current?.setSegment(117, 153);
                    controlRef.current?.stop();
                    controlRef.current?.play();

                    onLevelChange(currentLevel === LEVEL_2F ? currentLevel - 1 : currentLevel + 1);
                }}
            />
            <Player
                lottieRef={(ref) => controlRef.current = ref}
                src="/animations/control.json"
                renderer="svg"
                keepLastFrame={true}
            />
        </div>
    );
};

export default Control;