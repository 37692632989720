import React, {useEffect, useRef} from "react";
import Lottie from "lottie-web";
import { useTranslation } from "react-i18next";
import "./Header.scss";

interface IHeader {
    isReady: boolean;
    onChangeLanguage: (language: string) => void;
}
const Header = ({ isReady, onChangeLanguage }: IHeader) => {
    const logoRef = useRef(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        if (!isReady) {
            return;
        }

        Lottie.loadAnimation({
            container: logoRef.current! as Element,
            path: '/animations/logo.json',
            loop: false,
        });
    }, [isReady]);

    return (
        <div className="header">
            <a href="https://50k.io" target="_blank" rel="noopener noreferrer">
                <div ref={logoRef} />
            </a>
            <select
                value={i18n.language}
                onChange={(e) => onChangeLanguage(e.target.value)}
            >
                <option value="en">EN</option>
                <option value="ru">RU</option>
                <option value="kz">KZ</option>
                <option value="tr">TR</option>
            </select>
        </div>
    );
};

export default Header;