import React from "react";
import { Trans } from "react-i18next";
import { MODE_VILLAGE, MODE_HOUSES } from "../../constants";

interface IModeSelector {
    currentMode: number,
    onModeChange: (mode: number) => void
}
const ModeSelector = ({ currentMode, onModeChange }: IModeSelector) => {
    return (
        <div className="submenu submenu-mode round">
            <div
                className={currentMode === MODE_VILLAGE ? 'active' : ''}
                onClick={() => onModeChange(MODE_VILLAGE)}
            >
                <Trans i18nKey="tour.above.village">Above the village</Trans>
            </div>
            <div
                className={currentMode === MODE_HOUSES ? 'active' : ''}
                onClick={() => onModeChange(MODE_HOUSES)}
            >
                <Trans i18nKey="tour.above.houses">Above the houses</Trans>
            </div>
        </div>
    );
};

export default ModeSelector;