export const MODE_VILLAGE = 0;
export const MODE_HOUSES = 1;

export const LEVEL_100M = 0;
export const LEVEL_60M = 1;
export const LEVEL_10M = 2;
export const LEVEL_5M = 3;
export const LEVEL_2M = 4;
export const LEVEL_1F = 5;
export const LEVEL_2F = 6;

export const DIRECTION_EAST = 0;
export const DIRECTION_SOUTH = 1;
export const DIRECTION_WEST = 2;
export const DIRECTION_NORTH = 3;

export const TIME_MORNING = 0;
export const TIME_AFTERNOON = 1;
export const TIME_EVENING = 2;
export const TIME_NIGHT = 3;

export const HOUSE_MODE_NONE = 0;
export const HOUSE_MODE_360 = 1;
export const HOUSE_MODE_ROOF = 2;
export const HOUSE_MODE_PLOT = 3;
export const HOUSE_MODE_INTERIOR = 4;

export const PANEL_NONE = 0;
export const PANEL_CITY = 1;