import React from "react";
import { Trans } from "react-i18next";
import { DIRECTION_EAST, DIRECTION_NORTH, DIRECTION_SOUTH, DIRECTION_WEST } from "../../constants";

interface IDirectionSelector {
    currentDirection: number,
    onDirectionChange: (direction: number) => void
}
const DirectionSelector = ({ currentDirection, onDirectionChange }: IDirectionSelector) => {
    return (
        <div className="submenu submenu-direction">
            <div
                className={`${currentDirection === DIRECTION_EAST ? 'active' : ''}`}
                onClick={() => onDirectionChange(DIRECTION_EAST)}
            >
                <Trans i18nKey="tour.direction.east">East</Trans>
            </div>
            <div
                className={`${currentDirection === DIRECTION_SOUTH ? 'active' : ''}`}
                onClick={() => onDirectionChange(DIRECTION_SOUTH)}
            >
                <Trans i18nKey="tour.direction.south">South</Trans>
            </div>
            <div
                className={`${currentDirection === DIRECTION_WEST ? 'active' : ''}`}
                onClick={() => onDirectionChange(DIRECTION_WEST)}
            >
                <Trans i18nKey="tour.direction.west">West</Trans>
            </div>
            <div
                className={`${currentDirection === DIRECTION_NORTH ? 'active' : ''}`}
                onClick={() => onDirectionChange(DIRECTION_NORTH)}
            >
                <Trans i18nKey="tour.direction.north">North</Trans>
            </div>
        </div>
    );
};

export default DirectionSelector;