interface IHouseModelSize {
    plot: number;
    house: number;
    prise: number;
    kitchen: number;
    livingRoom: number;
    bedroom: number;
    guestRoom: number;
}

export interface IHouseModel {
    title: string;
    price: number;
    size: IHouseModelSize;
    sold: boolean;
    pool: boolean;
    playground: boolean;
    sportsField: boolean;
    recreationArea: boolean;
}

const houses: IHouseModel[] = [
    {
        title: '1',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: true,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },
    {
        title: '2',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: false,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },
    {
        title: '3',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: true,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },
    {
        title: '4',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: true,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },
    {
        title: '5',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: true,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },
    {
        title: '6',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: true,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },
    {
        title: '7',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: true,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },
    {
        title: '8',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: true,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },
    {
        title: '9',
        price: 314000,
        size: {
            plot: 900,
            house: 147,
            prise: 360,
            kitchen: 16,
            livingRoom: 20,
            bedroom: 22,
            guestRoom: 24,
        },
        sold: true,
        pool: true,
        playground: true,
        sportsField: true,
        recreationArea: true,
    },

];

export default houses;