import React from "react";
import { Trans } from "react-i18next";
import { IHouseModel } from "../../houses";
import { formatNumber } from "../../utils";
import { HOUSE_MODE_360, HOUSE_MODE_INTERIOR, HOUSE_MODE_NONE, HOUSE_MODE_PLOT, HOUSE_MODE_ROOF } from "../../constants";
import "./House.scss";

interface IHouse {
    index: number;
    currentHouse: number;
    house: IHouseModel;
    onHouseChange: (house: number) => void,
    currentHouseMode: number,
    onHouseModeChange: (mode: number) => void,
}
const House = ({ index, currentHouse, house, onHouseChange, currentHouseMode, onHouseModeChange }: IHouse) => {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

    const onButtonClick = (e: any, mode: number) => {
        e.preventDefault();
        e.stopPropagation();

        onHouseModeChange(mode);
    };

    return (
        <>
            {currentHouse === index && (
                <div className={`info ${isCollapsed ? 'collapsed' : ''}`} onClick={e => e.stopPropagation()}>
                    <div className="left">
                        <div className="title">
                            <h2><Trans i18nKey="tour.house.title">House</Trans> {house.title}</h2>
                            {!house.sold && (
                                <p>{formatNumber(house.price)} $</p>
                            )}
                            {house.sold && (
                                <b><Trans i18nKey="tour.house.sold">sold</Trans></b>
                            )}
                            {!house.sold && (
                                <span className="arrow" onClick={() => setIsCollapsed(prev => !prev)}>
                                    &#8691;
                                </span>
                            )}
                        </div>
                        {!house.sold && (
                            <div className="size">
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.size.plot">Plot</Trans>
                                    </span>
                                    <b>{house.size.plot} m<sup>2</sup></b>
                                </div>
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.size.house">House</Trans>
                                    </span>
                                    <b>{house.size.house} m<sup>2</sup></b>
                                </div>
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.size.prise">Prise</Trans>
                                    </span>
                                    <b>{house.size.prise} m<sup>2</sup></b>
                                </div>
                            </div>
                        )}
                        {!house.sold && (
                            <div className="buttons">
                                <button
                                    className={`${currentHouseMode === HOUSE_MODE_360 ? 'active' : ''}`}
                                    onClick={(e) =>
                                        onButtonClick(e, currentHouseMode === HOUSE_MODE_360
                                            ? HOUSE_MODE_NONE : HOUSE_MODE_360
                                        )
                                    }
                                >
                                    <Trans i18nKey="tour.house.buttons.tour">360 tour</Trans>
                                </button>
                                {currentHouseMode !== HOUSE_MODE_NONE && (
                                    <button
                                        className={`${currentHouseMode === HOUSE_MODE_ROOF ? 'active' : ''}`}
                                        onClick={(e) =>
                                            onButtonClick(e, currentHouseMode === HOUSE_MODE_ROOF
                                                ? HOUSE_MODE_360 : HOUSE_MODE_ROOF
                                            )
                                        }
                                    >
                                        {currentHouseMode !== HOUSE_MODE_ROOF && (
                                            <Trans i18nKey="tour.house.buttons.roof.remove">Remove the roof</Trans>
                                        )}
                                        {currentHouseMode === HOUSE_MODE_ROOF && (
                                            <Trans i18nKey="tour.house.buttons.roof.return">Return the roof</Trans>
                                        )}
                                    </button>
                                )}
                                {currentHouseMode !== HOUSE_MODE_NONE && (
                                    <button
                                        className={`${currentHouseMode === HOUSE_MODE_PLOT ? 'active' : ''}`}
                                        onClick={(e) => onButtonClick(e, HOUSE_MODE_PLOT)}
                                    >
                                        <Trans i18nKey="tour.house.buttons.walking">Plot walking</Trans>
                                    </button>
                                )}
                                {/*<button*/}
                                {/*    className={`${currentHouseMode === HOUSE_MODE_INTERIOR ? 'active' : ''}`}*/}
                                {/*    onClick={(e) => onButtonClick(e, HOUSE_MODE_INTERIOR)}*/}
                                {/*>*/}
                                {/*    <Trans i18nKey="tour.house.buttons.interior">Interior tour</Trans>*/}
                                {/*</button>*/}
                            </div>
                        )}
                    </div>
                    {!house.sold && (
                        <div className="right">
                            <div className="top">
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.size.kitchen">Kitchen</Trans>
                                    </span>
                                    <b>{house.size.kitchen} m<sup>2</sup></b>
                                </div>
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.size.livingRoom">Living room</Trans>
                                    </span>
                                    <b>{house.size.livingRoom} m<sup>2</sup></b>
                                </div>
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.size.bedroom">Bedroom</Trans>
                                    </span>
                                    <b>{house.size.bedroom} m<sup>2</sup></b>
                                </div>
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.size.guestRoom">Guest room</Trans>
                                    </span>
                                    <b>{house.size.guestRoom} m<sup>2</sup></b>
                                </div>
                            </div>
                            <div className="bottom">
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.pool">Pool</Trans>
                                    </span>
                                    <b>
                                        {house.pool
                                            ? <Trans i18nKey="tour.house.yes">yes</Trans>
                                            : <Trans i18nKey="tour.house.no">no</Trans>}
                                    </b>
                                </div>
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.playground">Playground</Trans>
                                    </span>
                                    <b>
                                        {house.playground
                                            ? <Trans i18nKey="tour.house.yes">yes</Trans>
                                            : <Trans i18nKey="tour.house.no">no</Trans>}
                                    </b>
                                </div>
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.sportsField">Sports field</Trans>
                                    </span>
                                    <b>
                                        {house.sportsField
                                            ? <Trans i18nKey="tour.house.yes">yes</Trans>
                                            : <Trans i18nKey="tour.house.no">no</Trans>}
                                    </b>
                                </div>
                                <div>
                                    <span>
                                        <Trans i18nKey="tour.house.recreationArea">Recreation area</Trans>
                                    </span>
                                    <b>
                                        {house.recreationArea
                                            ? <Trans i18nKey="tour.house.yes">yes</Trans>
                                            : <Trans i18nKey="tour.house.no">no</Trans>}
                                    </b>
                                </div>
                            </div>
                        </div>
                    )}
                    {!house.sold && (
                        <div className="buttons">
                            <button
                                className={`${currentHouseMode === HOUSE_MODE_360 ? 'active' : ''}`}
                                onClick={(e) => onButtonClick(e, HOUSE_MODE_360)}
                            >
                                <Trans i18nKey="tour.house.buttons.tour">360 tour</Trans>
                            </button>
                            <button
                                onClick={(e) =>
                                    onButtonClick(e, currentHouseMode === HOUSE_MODE_ROOF
                                        ? HOUSE_MODE_360 : HOUSE_MODE_ROOF
                                    )
                                }
                            >
                                {currentHouseMode !== HOUSE_MODE_ROOF && (
                                    <Trans i18nKey="tour.house.buttons.roof.remove">Remove the roof</Trans>
                                )}
                                {currentHouseMode === HOUSE_MODE_ROOF && (
                                    <Trans i18nKey="tour.house.buttons.roof.return">Return the roof</Trans>
                                )}
                            </button>
                            <button
                                className={`${currentHouseMode === HOUSE_MODE_PLOT ? 'active' : ''}`}
                                onClick={(e) => onButtonClick(e, HOUSE_MODE_PLOT)}
                            >
                                <Trans i18nKey="tour.house.buttons.walking">Plot walking</Trans>
                            </button>
                            <button
                                className={`${currentHouseMode === HOUSE_MODE_INTERIOR ? 'active' : ''}`}
                                onClick={(e) => onButtonClick(e, HOUSE_MODE_INTERIOR)}
                            >
                                <Trans i18nKey="tour.house.buttons.interior">Interior tour</Trans>
                            </button>
                        </div>
                    )}
                </div>
            )}
            <div
                className={`house ${house.sold ? 'sold' : 'sale'} ${currentHouse === index ? 'active' : ''}`}
                onClick={() => onHouseChange(index)}
            >
                <p>
                    <b><Trans i18nKey="tour.house.title">House</Trans></b> {house.title}
                </p>
                <span className="desktop">
                    {house.sold && <Trans i18nKey="tour.house.sold">sold</Trans>}
                    {!house.sold && <Trans i18nKey="tour.house.sale">sale</Trans>}
                </span>
                <span className="mobile">
                    {house.sold && <>sold</>}
                    {!house.sold && <>sale</>}
                </span>
            </div>
        </>
    );
};

export default House;