import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import Lottie from "lottie-web";
import { useTranslation } from "react-i18next";
import Header from "./containers/Header/Header";
import Tour from "./containers/Tour/Tour";
import AiChat from "./containers/AiChat/AiChat";
import Cookie from "./containers/Cookie/Cookie";
import './App.scss';

const App = () => {
    const loaderRef = useRef(null);
    const [loaderState, setLoaderState] = useState(0);

    const { i18n } = useTranslation();
    const onChangeLanguage = (language: string) => i18n.changeLanguage(language);

    useEffect(() => {
        const currentTime = Date.now();
        const animation = Lottie.loadAnimation({
            container: loaderRef.current! as Element,
            path: `/animations/preloader.json?time=${currentTime}`,
            loop: false,
        });

        animation.addEventListener('data_ready', () => {
            const readyTime = Date.now();
            const speed = ((258 * 1024) / ((readyTime - currentTime) / 1000)) / 1024 * 8;

            localStorage.setItem('speed', speed < 3000 ? 'fast' : 'fast');
        });

        animation.addEventListener('complete', () => {
            setLoaderState(1);

            setTimeout(() => {
                setLoaderState(2);
                document.body.className = '';
            }, 1000);
        });
    }, []);

    return (
        <div className="app">
            <div id="loader" ref={loaderRef} className={clsx(
                loaderState > 0 ? 'opacity' : '',
                loaderState > 1 ? 'hidden' : '',
            )} />
            <Header isReady={loaderState === 2} onChangeLanguage={onChangeLanguage} />
            <Tour isReady={loaderState === 2} />
            <Cookie/>
            <AiChat chatUrl="https://chat.50k.io" projectId="ecf969ae-4788-4f7c-b8bf-526b369467f1"/>
        </div>
    );
};

export default App;
